/* General styling for the navbar */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Navbar logo styling */
.navbarLogo {
  height: 40px; /* Adjust the height as needed */
  margin-right: 10px;
}

/* Navbar brand styling */
.navbarBrand {
  font-family: 'Dancing Script', cursive;
  font-size: 1.5em;
  color: #333333 !important;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  align-items: center; /* Align the logo and text vertically */
}

/* Navbar brand hover effect */
.navbarBrand:hover {
  color: #007bff !important;
  transform: scale(1.1);
}

/* Navbar link styling */
.navLink {
  color: #333333 !important;
  margin: 0 10px;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Navbar link hover effect */
.navLink:hover {
  color: #007bff !important;
  transform: scale(1.1);
}

/* Navbar toggler styling */
.navbar-toggler {
  border-color: #cccccc;
}

/* Navbar toggler icon */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2851%2C 51%2C 51%2C 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Form control styling */
.formControl {
  border: 1px solid #cccccc;
  border-radius: 20px;
  transition: all 0.3s ease;
}

/* Form control focus effect */
.formControl:focus {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

/* Button styling */
.btn-outline-success {
  color: #007bff;
  border-color: #007bff;
  transition: all 0.3s ease;
}

/* Button hover effect */
.btn-outline-success:hover {
  color: white;
  background-color: #007bff;
}

/* Media query for small screens */
@media (max-width: 767.98px) {
  .navbar-nav {
    text-align: center;
  }
  .navLink {
    margin: 10px 0;
  }
}

.flowerIconNav {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  transition: opacity 0.3s ease-in-out;
}

/* New CSS for the cart number flower image */
.cartIconContainer {
  position: relative;
  display: inline-block;
}

.cartNumberFlower {
  width: 30px; /* Adjust as necessary */
  height: 30px; /* Adjust as necessary */
}

.cartNumber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px; /* Adjust as necessary */
}
