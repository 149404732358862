/* Enhanced Cart Sidebar styles */
.cartSidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background: #ffffff;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.4s ease-in-out, box-shadow 0.3s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartSidebar.open {
  transform: translateX(0);
  box-shadow: -4px 0 20px rgba(0, 0, 0, 0.5);
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.cartHeader h3 {
  font-size: 1.85rem;
  margin: 0;
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease-in-out;
}

.closeButton:hover {
  color: #ff4d4d;
}

.cartItemList {
  flex-grow: 1;
  margin-bottom: 20px;
  overflow-y: auto;
}

.cartItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.4s ease;
}

.cartItemImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.cartItemImage:hover {
  transform: scale(1.05);
}

.cartItemDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}

.itemName {
  font-size: 1.3rem;
  margin: 0;
  font-weight: 500;
  color: #333;
}

.itemOption {
  font-size: 0.9rem;
  color: #888;
}

.itemPrice {
  font-size: 1.1rem;
  color: #555;
  margin-top: 5px;
}

.quantityControls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantityInput {
  width: 50px;
  text-align: center;
  margin: 0 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: border 0.3s ease;
}

.quantityInput:focus {
  border: 1px solid #007bff;
}

.quantityButton {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.quantityButton:hover {
  background-color: #ddd;
}

.removeButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.removeButton:hover {
  background-color: #ff0000;
  transform: translateY(-2px);
}

.cartFooter {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.cartTotal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.checkoutButton {
  width: 100%;
  background-color: #28a745;
  color: white;
  padding: 12px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.checkoutButton:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

/* Modal styles */
.modalContent {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  margin: auto;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modalButton {
  padding: 12px 25px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.modalButton:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.emptyCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures it takes the full height of the sidebar */
  text-align: center;
}

.emptyCartImage {
  max-width: 200px;
  height: auto;
  margin-bottom: 15px; /* Adds some spacing between the image and the message */
}

.emptyCartMessage {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.5;
}



/* Fade in up animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
