:root {
    --apm-primary-color: #007bff;
    --apm-primary-color-hover: #0056b3;
    --apm-success-color: #28a745;
    --apm-danger-color: #dc3545;
    --apm-bg-color: #f8f9fa;
    --apm-font-family: 'Arial', sans-serif;
    --apm-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    --apm-transition-speed: 0.3s;
    --apm-modal-bg-color: #ffffff;
    --apm-modal-width-sm: 50vw;
    --apm-heading-color: #343a40;
    --apm-modal-max-height: 80vh;
}

/* Admin Container */
.adminProductsContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;
    background-color: var(--apm-bg-color);
    border-radius: 8px;
    box-shadow: var(--apm-box-shadow);
    font-family: var(--apm-font-family);
}

/* Title */
.popupTitle {
    font-size: 32px;
    font-weight: bold;
    color: var(--apm-heading-color);
    text-align: center;
    margin-bottom: 40px;
}

/* Section Titles */
.sectionTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--apm-heading-color);
    margin-bottom: 20px;
}

/* Admin Table */
.adminTableContainer {
    overflow-x: auto;
}

.adminTable {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: var(--apm-box-shadow);
}

.adminTable th, .adminTable td {
    padding: 12px 20px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
}

.adminTable th {
    background-color: #007bff;
    color: white;
}

.adminTable td {
    color: #343a40;
}

.adminTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Buttons in the table */
.tableButton {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
}

.tableButton:hover {
    opacity: 0.9;
}

.activeButton {
    background-color: var(--apm-success-color);
}

.deleteButton {
    background-color: var(--apm-danger-color);
}

/* Product List */
.productList {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 20px;
}

/* Product Item */
.productItem {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productInfo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.productImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Image Preview and Principal Image */
.imagePreview {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.imageThumbnail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.imageThumbnail img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.activeImageButton {
    background-color: var(--apm-success-color);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: default;
    border: none;
}

.inactiveImageButton {
    background-color: var(--apm-primary-color);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.inactiveImageButton:hover {
    background-color: var(--apm-primary-color-hover);
}

/* Centered Buttons */
.centeredButtonRow {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

/* Buttons */
.popupButton {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: var(--apm-primary-color);
    transition: background-color var(--apm-transition-speed);
}

.popupButton:hover {
    background-color: var(--apm-primary-color-hover);
}

/* Loading Overlay */
.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingOverlay:before {
    content: '';
    width: 50px;
    height: 50px;
    border: 5px solid var(--apm-primary-color);
    border-top: 5px solid var(--apm-modal-bg-color);
    border-radius: 50%;
    animation: apm-spin 1s linear infinite;
}

@keyframes apm-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Modal */
.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Categories Section */
.categoryOptionsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust grid columns dynamically */
    gap: 10px; /* Space between categories */
    margin-bottom: 20px; /* Add spacing below the categories */
}

.categoryOptionLabel {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and label text */
    padding: 8px 12px; /* Add padding around each category */
    border-radius: 5px; /* Round the corners */
    background-color: #f9f9f9; /* Light background */
    border: 1px solid #ddd; /* Add a border */
    cursor: pointer; /* Indicate clickability */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Add slight shadow */
}

.categoryOptionLabel:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.addCategoryLabel {
    margin-top: 20px; /* Add space above "Agregar Nueva Categoría" */
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    padding: 8px 12px;
    background-color: #fff9e6; /* Slightly distinct background */
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.addCategoryLabel input {
    margin-left: 5px;
    cursor: pointer;
}

/* Center Modal Content */
.ReactModal__Content {
    width: 50vw; /* Keep modal width at 50% of the viewport */
    max-height: var(--apm-modal-max-height); /* Ensure it doesn't exceed screen height */
    overflow-y: auto; /* Allow scrolling for long content */
    background-color: var(--apm-modal-bg-color); /* Keep modal background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Internal padding */
    box-shadow: var(--apm-box-shadow); /* Subtle shadow */
    position: fixed; /* Ensure it's fixed in the viewport */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    z-index: 1000; /* Ensure it stays on top of other elements */
}


/* Modal Header */
.modalHeader {
    background-color: var(--apm-primary-color);
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Form centered content */
.formCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputField {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.textAreaField {
    width: 100%;
    max-width: 500px;
    height: 120px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Switch Style for Checkbox */
.switchLabel {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.switchCheckbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchSlider {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.switchSlider:before {
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: white;
    left: 1px;
    bottom: 1px;
    transition: transform 0.3s;
}

.switchCheckbox:checked + .switchSlider {
    background-color: var(--apm-success-color);
}

.switchCheckbox:checked + .switchSlider:before {
    transform: translateX(25px);
}

/* Button Row Alignment */
.buttonRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
}

.productInfo {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 5px; /* Add space between lines */
    padding-left: 15px; /* Add space between the image and text */
  }
  
  .productRow {
    display: flex;
    align-items: flex-start;
    gap: 20px; /* Space between image and text */
  }
  
  .productImage {
    width: 100px; /* Adjust image size as needed */
    height: 100px;
    object-fit: cover; /* Ensure the image maintains aspect ratio */
    border-radius: 8px; /* Optional: Add rounded corners */
  }
  
 
  