.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .profileContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cardTitle {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #555;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input:read-only {
    background-color: #e9ecef;
  }
  
  .saveButton,
  .signOutButton,
  .orderHistoryButton {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover,
  .signOutButton:hover,
  .orderHistoryButton:hover {
    background-color: #0056b3;
  }
  
  .orderHistoryButton {
    text-align: center;
    display: block;
    margin-top: 15px;
    text-decoration: none;
  }
  
  .signOutButton {
    background-color: #6c757d;
  }
  
  .signOutButton:hover {
    background-color: #5a6268;
  }
  