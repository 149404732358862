:root {
  --ahc-modal-bg-color: #ffffff;
  --ahc-modal-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --ahc-button-bg-color: #007bff;
  --ahc-button-hover-color: #0056b3;
  --ahc-primary-padding: 20px;
  --ahc-font-family: 'Arial', sans-serif;
  --ahc-modal-width: 50vw;
  --ahc-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --ahc-success-color: #28a745;
  --ahc-danger-color: #dc3545;
  --ahc-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --ahc-transition-speed: 0.3s;
  --ahc-table-border-color: #dee2e6;
  --ahc-table-header-bg: #343a40;
  --ahc-table-header-color: #ffffff;
  --ahc-table-hover-bg: #f8f9fa;
}

/* General container styles */
.adminHomepageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--ahc-primary-padding);
  padding-top: 80px;
  animation: ahc-fadeIn 0.5s ease-in-out;
  font-family: var(--ahc-font-family);
  background-color: #f8f9fa;
  width: 100%;
}

.adminHomepageContent h2,
.adminHomepageContent h3,
.adminHomepageContent h4 {
  text-align: center;
  color: #343a40;
  font-weight: bold;
  margin-bottom: 20px;
}

.adminHomepageContent button {
  margin: 5px;
  padding: 10px 20px;
  background-color: var(--ahc-button-bg-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color var(--ahc-transition-speed);
  font-size: 1rem;
  font-weight: 600;
}

.adminHomepageContent button:hover {
  background-color: var(--ahc-button-hover-color);
}

/* Table Styles */
.contentTable {
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: var(--ahc-box-shadow);
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.contentTable th,
.contentTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid var(--ahc-table-border-color);
}

.contentTable th {
  background-color: var(--ahc-table-header-bg);
  color: var(--ahc-table-header-color);
}

.contentTable tbody tr:hover {
  background-color: var(--ahc-table-hover-bg);
}

/* Align buttons within the actions column */
.contentTable td.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentTable td.actions button {
  flex: 1;
  margin: 0 5px;
}

.contentTable td.actions .setActiveButton,
.contentTable td.actions .editButton,
.contentTable td.actions .deleteButton,
.contentTable td.actions .activeButton {
  padding: 8px 12px;
  width: 100%;
  text-align: center;
}

/* Custom button styles */
.adminHomepageContent .disableButton {
  background-color: var(--ahc-danger-color);
}

.adminHomepageContent .activeButton {
  background-color: var(--ahc-success-color);
}

.adminHomepageContent .setActiveButton {
  background-color: var(--ahc-button-bg-color);
}

.adminHomepageContent .editButton {
  background-color: #ffc107;
}

.adminHomepageContent .deleteButton {
  background-color: var(--ahc-danger-color);
}

.adminHomepageContent .editButton:hover,
.adminHomepageContent .deleteButton:hover {
  background-color: darken(#ffc107, 10%);
}

/* Sections within content */
.adminHomepageContent .section {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: var(--ahc-box-shadow);
  transition: transform var(--ahc-transition-speed);
}

.adminHomepageContent .section:hover {
  transform: scale(1.05);
}

.adminHomepageContent .section input[type="text"],
.adminHomepageContent .section select,
.adminHomepageContent .section textarea {
  width: 100%;
  margin-bottom: 10px;
}

/* Image preview styles */
.imagePreview {
  width: 60px; /* Smaller size for image previews */
  height: 60px;
  object-fit: cover; /* Ensures images are cropped to fit the container without distortion */
  margin-top: 10px;
  border-radius: 8px; /* Add rounded corners for a more polished look */
  box-shadow: var(--ahc-box-shadow); /* Add subtle shadow for better focus */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.imagePreview:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Carousel image grid with a layout of 5 images per row */
.adminHomepageContent .carouselImageGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 images per row */
  gap: 10px; /* Space between images */
  margin-top: 10px;
}

.adminHomepageContent .carouselImageContainer {
  position: relative;
}

.adminHomepageContent .deleteCarouselButton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.adminHomepageContent .deleteCarouselButton:hover {
  background-color: darkred;
}

/* Modal styles */
.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--ahc-modal-width);
  max-height: 80vh;
  background-color: var(--ahc-modal-bg-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--ahc-box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 1001;
}

/* Modal overlay */
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ahc-overlay-bg-color);
  z-index: 1000;
}

/* Loading overlay */
.adminHomepageContent .loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ahc-overlay-bg-color);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminHomepageContent .loadingOverlay:before {
  content: '';
  width: 50px;
  height: 50px;
  border: 5px solid var(--ahc-button-bg-color);
  border-top: 5px solid var(--ahc-modal-bg-color);
  border-radius: 50%;
  animation: ahc-spin 1s linear infinite;
}

/* Keyframe animations */
@keyframes ahc-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ahc-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal header and body styling */
.modalHeader {
  background-color: var(--ahc-button-bg-color);
  color: white;
  padding: 15px;
  text-align: center;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.modalBody input,
.modalBody textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Button row within the modal */
.modalButtonRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.modalButtonRow button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  flex: 1;
}

.modalCloseButton {
  background-color: var(--ahc-danger-color);
  color: white;
}

.modalCloseButton:hover {
  background-color: #c82333;
}

.modalSubmitButton {
  background-color: var(--ahc-success-color);
  color: white;
}

.modalSubmitButton:hover {
  background-color: darken(var(--ahc-success-color), 10%);
}

.modalAddSectionButton {
  background-color: var(--ahc-button-bg-color);
  color: white;
}

.modalAddSectionButton:hover {
  background-color: var(--ahc-button-hover-color);
}

/* Loading overlay for image uploads */
.adminHomepageContent .imageLoadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ahc-overlay-bg-color); /* Semi-transparent background */
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminHomepageContent .imageLoadingOverlay:before {
  content: '';
  width: 50px;
  height: 50px;
  border: 5px solid var(--ahc-button-bg-color); /* Blue border */
  border-top: 5px solid var(--ahc-modal-bg-color); /* White border for top to create the spinner effect */
  border-radius: 50%;
  animation: ahc-spin 1s linear infinite;
}

/* Keyframe animations for spinning loader */
@keyframes ahc-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
