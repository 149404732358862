.orderHistoryContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', sans-serif;
  padding-top: 80px;
}

.orderHistoryTitle {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  animation: fadeInDown 1s ease;
}

.orderListGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.orderListItem {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 40px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.7s ease;
  transition: all 0.3s ease;
}

.orderListItem:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.orderNumber {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.orderNumber:hover {
  color: #0056b3;
}

.orderText {
  font-size: 1.2rem;
  color: #555;
  margin: 10px 0;
}

.orderItemList {
  margin-top: 20px;
  padding-left: 20px;
}

.orderItem {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.orderItem:hover {
  opacity: 1;
  transform: translateX(5px);
}

.orderTotal {
  font-weight: bold;
  font-size: 1.3rem;
}

.orderLink {
  color: #28a745;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.orderLink:hover {
  color: #218838;
  text-decoration: underline;
}

hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .orderHistoryContainer {
    padding: 10px;
  }

  .orderListItem {
    padding: 20px;
  }

  .orderHistoryTitle {
    font-size: 1.8rem;
  }

  .orderNumber {
    font-size: 1.3rem;
  }

  .orderText, .orderItem {
    font-size: 1rem;
  }
}
