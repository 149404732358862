/* Ensure that the entire viewport height is used by the page */
body, html, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* General body styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/* For code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Ensure the footer stays at the bottom */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  flex-shrink: 0;
}

.App-content {
  flex-grow: 1;
  padding: 0; /* Remove padding to avoid extra spaces */
  /* padding-top will be set dynamically via JavaScript */
}

footer {
  flex-shrink: 0;
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

footer ul {
  list-style-position: inside;
  padding-left: 0;
  margin-left: 0;
}

footer ul li {
  padding-left: 0;
  margin-left: 0;
  list-style-position: inside;
  display: list-item;
}

footer ul li::before {
  content: '';
  margin-right: 0;
}

/* Sticky Navbar */
.sticky-navbar {
  position: sticky !important;
  top: 0;
  z-index: 1000 !important;
  width: 100%;
}

/* Responsive Navbar */
@media (max-width: 767.98px) {
  .navbar-nav {
    text-align: center;
  }
  .navbar-nav .nav-link {
    margin: 10px 0;
  }
}

.adminHomepageContent {
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

h3 {
  margin-top: 20px;
}

h4 {
  margin-bottom: 10px;
}

button {
  margin: 5px;
}

input[type="text"],
textarea,
select {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

input[type="file"] {
  display: block;
  margin: 10px 0;
}

textarea {
  height: 100px;
}

.section {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #f9f9f9;
}

.section input[type="text"],
.section select,
.section textarea {
  width: 100%;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Adjust modal width and centering */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
