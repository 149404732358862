.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .loadingText {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-family: 'Tangerine', cursive;
    color: #333;
    text-align: center;
  }
  
  .sunflower {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  