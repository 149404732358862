/* src/components/PolicyPage.module.css */

.policyWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px); /* Adjusted to ensure space below the navbar */
    padding-top: 70px; /* Add padding equal to navbar height to avoid overlap */
    padding-bottom: 20px; /* Additional bottom padding for spacing */
    box-sizing: border-box;
}

.policyContainer {
    padding: 20px;
    max-width: 800px;
    width: 100%; /* Ensure the container takes full width available */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.8s ease-in-out;
}

.policyTitle {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 10px;
}

.policySection {
    margin-bottom: 20px;
}

.policySectionTitle {
    font-size: 24px;
    color: #4CAF50;
    margin-bottom: 10px;
}

.policyText {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
