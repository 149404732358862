:root {
  --catalog-modal-width: 80%;
  --catalog-modal-max-width: 800px;
  --catalog-modal-padding: 20px;
  --catalog-modal-bg-color: #fff;
  --catalog-modal-border-radius: 10px;
  --catalog-modal-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  --catalog-modal-overlay-bg: rgba(0, 0, 0, 0.4);
  --catalog-modal-title-font-size: 1.5rem;
  --catalog-modal-btn-padding: 10px 20px;
  --catalog-modal-btn-font-size: 1rem;
}

.catalogContainer {
  padding-top: 80px; /* Add top padding to avoid navbar overlap */
}

/* General Styles */
.container {
  max-width: 1200px;
  margin: auto;
}

/* Card Styles */
.card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  min-height: 450px; /* Set a minimum height to standardize card size */
  max-width: 100%; /* Ensure cards take up the full width of the column */
  width: 100%; /* Ensure cards take up the full width of the column */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

/* Image Container */
.card-img-container {
  width: 100%;
  height: 400px; /* Standardize image container height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* For overlaying the spinner placeholder */
  background-color: #f7f7f7; /* Fallback background color */
}

/* Spinner Placeholder */
.imagePlaceholder {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 4px solid #ddd;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
  opacity: 1; /* Spinner visible initially */
  transition: opacity 0.3s ease-in-out;
}

/* Spinner Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Image Style */
.card-img-top {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* Hide image initially */
}

/* Make image visible and hide spinner when loaded */
.card-img-top.imageLoaded {
  opacity: 1;
}

.card-img-container .imageLoaded ~ .imagePlaceholder {
  opacity: 0; /* Hide spinner when the image is loaded */
}

/* Card Body */
.card-body {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Make the card body take up the remaining space */
}

.card-body:hover {
  background-color: #f9f9f9;
}

/* Card Title */
.card-title {
  transition: color 0.3s ease-in-out;
  font-size: 1.4em;
  margin-bottom: 15px;
  font-weight: bold;
}

.card-title:hover {
  color: #007bff;
}

/* Card Text */
.card-text {
  font-size: 1em;
  color: #666;
}

.productPrice {
  font-weight: bold;
  margin: 10px 0;
  font-size: 1.2em;
  color: #333;
}

.productCategory, .productStock {
  font-size: 0.9em;
  color: #888;
}

.productButton {
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.productButton:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004494;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #545b62;
  border-color: #4e555b;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .card-body {
    padding: 15px;
  }

  .card-title {
    font-size: 1.2em;
  }

  .productPrice {
    font-size: 1em;
  }
}

.searchBar,
.categorySelect,
.sortSelect {
  border-radius: 25px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 1rem;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 10px;
}

.searchBar:focus,
.categorySelect:focus,
.sortSelect:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  border-color: #007bff;
}

.searchBar:hover,
.categorySelect:hover,
.sortSelect:hover {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.searchBar::placeholder {
  color: #bbb;
  font-style: italic;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .searchContainer {
    flex-direction: row;
    align-items: center;
  }

  .searchBar,
  .categorySelect,
  .sortSelect {
    flex: 1;
  }

  .searchBar,
  .categorySelect {
    margin-right: 10px;
  }

  .sortSelect {
    margin-left: 10px;
  }
}

/* Modal Styles specific to ProductCatalog */
.catalogModalContent {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: var(--catalog-modal-width);
  max-width: var(--catalog-modal-max-width);
  height: auto;
  padding: var(--catalog-modal-padding);
  background-color: var(--catalog-modal-bg-color);
  border-radius: var(--catalog-modal-border-radius);
  box-shadow: var(--catalog-modal-box-shadow);
}

.catalogModalOverlay {
  background-color: var(--catalog-modal-overlay-bg);
}

.catalogModalTitle {
  font-size: var(--catalog-modal-title-font-size);
  margin-bottom: 10px;
}

.catalogModalButton {
  padding: var(--catalog-modal-btn-padding);
  font-size: var(--catalog-modal-btn-font-size);
  margin-top: 15px;
}

.catalogModalButtonPrimary:hover {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.catalogModalButtonSecondary:hover {
  background-color: #6c757d;
  color: #fff;
  cursor: pointer;
}

.flowerAnimation {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1000;
  pointer-events: none;
}
