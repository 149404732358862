/* src/components/ForgotPassword.module.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    animation: fadeIn 0.8s ease-in-out;
  }
  
  .formWrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .message {
    text-align: center;
    color: #4caf50;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  input[type="email"] {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  input[type="email"]:focus {
    border-color: #555;
  }
  
  .btnPrimary {
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btnPrimary:hover {
    background-color: #45a049;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  