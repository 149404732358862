/* General Styles */
:root {
    --primary-color: #A47551; /* Soft, earthy brown */
    --secondary-color: #d7bcb8; /* Blush pink for accent */
    --success-color: #A3D9A5; /* Pastel green for success */
    --danger-color: #E8A0A0; /* Light coral for warnings */
    --bg-color: #FAF6F0; /* Creamy white background */
    --table-bg-color: #FFF9F6; /* Light pastel peach for tables */
    --border-color: #E1D5D1; /* Soft, pastel beige for borders */
    --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    --transition-speed: 0.3s;
    --modal-width-lg: 70vw;
    --modal-width-sm: 90vw;
}

/* Container Styling */
.adminUsersContainer {
    padding: 20px;
    padding-top: 80px;
    max-width: 1400px;
    margin: auto;
    background-color: var(--bg-color);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}

/* Title */
.adminUsersTitle {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--primary-color);
    font-weight: bold;
}

/* Table Styles */
.adminUsersTable {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--table-bg-color);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.adminUsersTable th,
.adminUsersTable td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    transition: background-color var(--transition-speed);
}

.adminUsersTable th {
    background-color: var(--primary-color);
    color: #ffffff;
}

.adminUsersTable tr:hover {
    background-color: rgba(163, 217, 165, 0.15); /* Soft pastel green highlight */
}

/* Button Styles */
.adminUsersButton {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    transition: transform var(--transition-speed), background-color var(--transition-speed);
    font-size: 14px;
}

.adminUsersButton:hover {
    transform: translateY(-2px);
    background-color: var(--success-color);
}

/* Modal Styles */
.modalHeader {
    background-color: var(--primary-color);
    color: #ffffff;
}

.modalBody {
    padding: 20px;
    background-color: var(--table-bg-color);
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
}

.modalCloseButton {
    padding: 8px 12px;
    border-radius: 5px;
    background-color: var(--danger-color);
    color: #ffffff;
    cursor: pointer;
    transition: background-color var(--transition-speed);
}

.modalCloseButton:hover {
    background-color: #e57373;
}

/* Loading Overlay */
.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingOverlay:before {
    content: '';
    width: 50px;
    height: 50px;
    border: 5px solid var(--primary-color);
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Media Queries */
@media (min-width: 768px) {
    .adminUsersTable th,
    .adminUsersTable td {
        padding: 15px 20px;
    }
}

@media (max-width: 768px) {
    .adminUsersTable th,
    .adminUsersTable td {
        padding: 10px;
        font-size: 14px;
    }

    .adminUsersButton {
        width: 100%;
        margin-bottom: 5px;
    }

    .modal-lg {
        width: var(--modal-width-lg);
    }

    .modal-sm {
        width: var(--modal-width-sm);
    }
}
