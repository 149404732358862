/* checkout.module.css */

.checkoutContainer {
  /* Scoped variables specific to checkout */
  --primary-color: #ff6b6b;
  --secondary-color: #ffe6e6;
  --bg-color: #fff7f7;
  --border-color: #f8d7da;
  --text-color: #4a4a4a;
  --highlight-color: #ffe0e0;
  --btn-hover-color: #ff4d4d;
  --spinner-color: #ff6b6b;

  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  padding-top: 80px;
  background: var(--bg-color);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  font-size: 1.8rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sectionTitle {
  font-size: 1.2rem;
  color: var(--text-color);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.icon {
  position: absolute;
  left: 12px;
  color: var(--primary-color);
}

.formControl {
  width: 100%;
  padding: 0.75rem 1rem;
  padding-left: 2.5rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s;
  background-color: #fff;
  outline: none;
}

.formControl:focus {
  box-shadow: 0 0 8px var(--border-color);
  background-color: var(--highlight-color);
}

/* Shortened delivery date input */
.shortInput {
  width: 50%;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkboxGroup input[type="checkbox"] {
  margin-right: 0.5rem;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radioGroup label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.radioGroup input[type="radio"] {
  margin-right: 0.5rem;
}

.timePicker {
  display: flex;
  gap: 0.5rem;
}

.pickupInfo {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
}

.orderSummarySection {
  background-color: #fafafa;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
}

.orderSummaryList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.orderItem {
  background: #fff;
  border-radius: 6px;
  padding: 0.75rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s;
}

.orderItem:hover {
  background: var(--highlight-color);
}

.totalTitle {
  text-align: right;
  font-weight: bold;
  color: var(--text-color);
  margin-top: 1rem;
  font-size: 1.2rem;
}

.checkoutBtn {
  display: inline-block;
  width: 100%;
  background: var(--primary-color);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease, transform 0.2s;
}

.checkoutBtn:hover {
  background: var(--btn-hover-color);
  transform: scale(1.02);
}

.checkoutBtn:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner,
.spinnerSmall {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--spinner-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner {
  width: 40px;
  height: 40px;
}

.spinnerSmall {
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Payment Instructions */
.paymentInstructions {
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
  color: #0c5460;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 400px;
}

.modalContent h2 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.modalContent p {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.modalContent .btnPrimary {
  width: 100%;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  padding: 0.75rem;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modalContent .btnPrimary:hover {
  background-color: var(--btn-hover-color);
}

/* Pickup Info (Yellow Box) */
.pickupInfo {
  background-color: #fff3cd;
  border-left: 6px solid #ffeeba;
  color: #856404;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Payment Instructions (Blue Box) */
.paymentInstructions {
  background-color: #d1ecf1;
  border-left: 6px solid #bee5eb;
  color: #0c5460;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Text inside the instruction boxes */
.pickupInfo p,
.paymentInstructions p {
  margin: 0.5rem 0;
  font-weight: 500;
}

.pickupInfo p strong,
.paymentInstructions p strong {
  color: #333;
}

.formControl {
  width: 80%;
  max-width: 400px;
}

.orderItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.orderItemImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.orderItemDetails {
  flex: 1;
}
