/* General styling */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --bg-color: #f9f9f9;
  --font-family: 'Arial', sans-serif;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
  --flower-animation-duration: 1s;
  --title-color: #333;
  --price-color: #333;
  --instock-color: #28a745;
  --outstock-color: #dc3545;
}

.productContainer {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  padding-top: 90px;
  font-family: var(--font-family);
  background-color: var(--bg-color);

}

.productTitle {
  font-size: 28px;
  color: var(--title-color);
  font-weight: bold;
  margin-bottom: 15px;
}

.productImage {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto; /* Center the image */
}

.productPrice {
  font-size: 24px;
  color: var(--price-color);
  font-weight: bold;
}

.productShortDescription {
  font-size: 18px;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.productFullDescription {
  font-size: 16px;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.productStock {
  font-size: 18px;
  font-weight: bold;
}

.productStock.inStock {
  color: var(--instock-color);
}

.productStock.outStock {
  color: var(--outstock-color);
}

.productButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #ffffff;
  cursor: pointer;
  transition: background-color var(--transition-speed), transform var(--transition-speed);
  margin-top: 10px;
}

.productButton:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.productButton:hover {
  background-color: var(--success-color);
  transform: translateY(-2px);
}

.flowerAnimation {
  position: absolute;
  width: 40px;
  height: 40px;
  transition: all var(--flower-animation-duration) ease-in-out;
}

.reviewSection {
  margin-top: 40px;
}

.reviewTitle {
  font-size: 24px;
  margin-bottom: 15px;
}

.listGroupItem {
  padding: 15px;
  background-color: var(--bg-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  margin-bottom: 10px;
}

.modalContent {
  text-align: center;
}

button {
  border-radius: 5px;
}
