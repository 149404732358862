:root {
  --apm-primary-color: #007bff;
  --apm-primary-color-hover: #0056b3;
  --apm-success-color: #28a745;
  --apm-danger-color: #dc3545;
  --apm-bg-color: #f8f9fa;
  --apm-font-family: 'Arial', sans-serif;
  --apm-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --apm-transition-speed: 0.3s;
  --apm-modal-bg-color: #ffffff;
  --apm-modal-width-sm: 50vw; /* Smaller modal width */
  --apm-highlight-color: #f5f8fa;
  --apm-heading-color: #343a40;
}

/* General Container */
.popupManagementContainer {
  max-width: 1000px;
  margin: auto;
  padding: 80px 20px;
  background-color: var(--apm-bg-color);
  border-radius: 10px;
  box-shadow: var(--apm-box-shadow);
  font-family: var(--apm-font-family);
}

/* Title */
.popupTitle {
  font-size: 32px;
  font-weight: bold;
  color: var(--apm-heading-color);
  text-align: center;
  margin-bottom: 40px;
}

/* Section Titles */
.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  color: var(--apm-heading-color);
  margin-bottom: 20px;
}

/* Popup Table */
.popupTable {
  width: 100%;
  border-collapse: collapse;
}

.popupTableRowHeader, .popupTableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.popupTableHeader, .popupTableCell {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.popupTableHeader {
  font-weight: bold;
  background-color: var(--apm-primary-color);
  color: white;
}

/* Popup Items */
.popupItem {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--apm-highlight-color);
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Input Fields */
.inputField, .textAreaField, .inputFile {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.textAreaField {
  height: 100px;
}

/* Buttons */
.popupButton {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background-color: var(--apm-primary-color);
  transition: background-color var(--apm-transition-speed);
}

.popupButton:hover {
  background-color: var(--apm-primary-color-hover);
}

.disableButton {
  background-color: var(--apm-danger-color);
}

.activeButton {
  background-color: var(--apm-success-color);
}

/* Modal */
.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darken background */
  z-index: 1000;
}

.ReactModal__Content {
  width: var(--apm-modal-width-sm);
  background-color: var(--apm-modal-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--apm-box-shadow);
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto; /* Centers the modal vertically and horizontally */
}

/* Modal Header */
.modalHeader {
  background-color: var(--apm-primary-color);
  color: white;
  padding: 10px;
  text-align: center;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Modal Footer */
.modalCloseButton {
  background-color: var(--apm-danger-color);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modalCloseButton:hover {
  background-color: #c82333;
}

/* Modal Body */
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.modalBody input,
.modalBody textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Center the popup list items */
.popupManagementContainer .popupItem {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: var(--apm-modal-bg-color);
  box-shadow: var(--apm-box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.popupManagementContainer .popupItem h4 {
  flex-grow: 1;
  text-align: center;
}

/* Loading Overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingOverlay:before {
  content: '';
  width: 50px;
  height: 50px;
  border: 5px solid var(--apm-primary-color);
  border-top: 5px solid var(--apm-modal-bg-color);
  border-radius: 50%;
  animation: apm-spin 1s linear infinite;
}

@keyframes apm-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Delete Confirmation Modal */
.confirmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmModal {
  background-color: var(--apm-modal-bg-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--apm-box-shadow);
  text-align: center;
}

.confirmModal h3 {
  margin-bottom: 20px;
}

.confirmModal button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  color: #fff;
  cursor: pointer;
}

.confirmModal button:first-of-type {
  background-color: var(--apm-danger-color);
}

.confirmModal button:last-of-type {
  background-color: var(--apm-primary-color);
}

.confirmModal button:hover {
  opacity: 0.9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .popupManagementContainer .modalBody input,
  .popupManagementContainer .modalBody textarea {
    width: 90%;
  }
}
