/* Center the entire eventos content */
.eventosContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center; /* Ensure text is centered */
  width: 100%;
}

.heroSection {
  width: 100%;
  height: 400px; /* Default height */
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hero image with responsive adjustments */
.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Default centering for all screens */
}

/* Centered hero text */
.heroText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .heroSection {
    height: 300px; /* Reduced height for mobile */
  }

  .heroImage {
    object-position: center top; /* Focuses on top of image for mobile */
  }

  .heroText {
    font-size: 1.8rem; /* Adjusted text size for mobile */
    padding: 15px 30px;
  }
}

/* Large desktop adjustments */
@media (min-width: 1440px) {
  .heroSection {
    height: 500px; /* Increased height for large screens */
  }

  .heroImage {
    object-position: center; /* Keeps image centered on large screens */
  }
}


/* Style the content title and description */
.eventosContent h1,
.eventosContent p {
  text-align: center; /* Ensure both title and description are centered */
  margin: 20px auto; /* Center horizontally */
}

.eventosContent h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
}

.eventosContent p {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 800px; /* Optional: Set max width for readability */
}

/* Center the sections */
.section {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto; /* Center each section */
  text-align: center;
}

/* Responsive resizing for Normal Section */
.sectionImage {
  width: 100%;
  max-width: 700px;
  max-height: 700px; /* Set maximum height */
  object-fit: cover;
  margin: 20px auto;
  border-radius: 10px;
  transition: max-height 0.3s ease;
}

/* Center the carousel container */
.carouselContainer {
  width: 100%;
  max-width: 1200px; /* Optional: Set a max width for the carousel */
  margin: 0 auto; /* Center the carousel container */
}

/* Center each carousel item */
.carouselItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center; /* Ensure the caption text is centered */
}

/* Ensure carousel images are centered */
.carouselImage {
  width: 100%;
  height: 500px; /* Set a standard height */
  object-fit: cover;
  border-radius: 10px; /* Optional: Add rounded corners */
}

/* Center the caption of the carousel */
.carouselCaption {
  text-align: center;
  padding: 20px;
}

/* On Image Section */
.onImageSection {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.onImageSectionImage {
  width: 100%;
  max-height: 700px; /* Limit height for larger screens */
  object-fit: cover;
  border-radius: 10px;
  transition: max-height 0.3s ease;
}

/* Center the onImage text */
.onImageSectionText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

/* Center the modal */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popupImage {
  width: 100%;
  height: auto;
  max-width: 300px;
  margin-bottom: 20px;
}

/* Styles for Reviews Section */
.reviewCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensure content is spaced evenly */
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  background-color: #fff;
  height: 300px; /* Set a fixed height */
  overflow: hidden; /* Prevent overflow of content */
}

.reviewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 150px; /* Adjust height for content */
}

.reviewContent h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.reviewContent p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit the text to 3 lines */
  -webkit-box-orient: vertical;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.textSection p {
  white-space: pre-wrap; /* This ensures that line breaks are respected */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .heroText {
    font-size: 2rem;
  }

  .onImageSectionImage,
  .sectionImage {
    max-height: 250px; /* Smaller height for mobile */
  }

  .carouselImage {
    height: 300px; /* Adjusted height for smaller screens */
  }

  .avatar {
    width: 50px;
    height: 50px;
  }
}
