/* src/components/AdminManagement.module.css */

.adminManagementContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.adminManagementTitle {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.loggedInUser {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

.inputGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.inputField {
  width: 60%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.addButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #0056b3;
}

.adminList {
  list-style-type: none;
  padding: 0;
}

.adminItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.adminEmail {
  font-size: 1em;
  color: #333;
}

.removeButton {
  padding: 5px 15px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.removeButton:hover {
  background-color: #d9363e;
}

/* Loading Overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingOverlay:before {
  content: '';
  width: 50px;
  height: 50px;
  border: 5px solid #007bff;
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}





/* Updated styling for better UX */
.businessSettings {
  margin-top: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.switchLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1em;
  margin-bottom: 15px;
}

.switchInput {
  display: none;
}

.switchSlider {
  position: relative;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switchInput:checked + .switchSlider {
  background-color: #4caf50;
}

.switchSlider:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switchInput:checked + .switchSlider:before {
  transform: translateX(25px);
}

.timeSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;
}

.timeSettings label {
  flex: 1; /* Equal width for labels */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  margin-top: 5px;
}

.updateButton {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-end; /* Align the button to the right */
}

.updateButton:hover {
  background-color: #0056b3;
}






@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
