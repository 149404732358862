/* General Styles */
:root {
    --primary-color: #A47551; /* Soft, earthy brown */
    --secondary-color: #d7bcb8; /* Blush pink for accent */
    --success-color: #A3D9A5; /* Pastel green for success */
    --danger-color: #E8A0A0; /* Light coral for warnings */
    --bg-color: #FAF6F0; /* Creamy white background */
    --table-bg-color: #FFF9F6; /* Light pastel peach for tables */
    --border-color: #E1D5D1; /* Soft, pastel beige for borders */
    --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    --transition-speed: 0.3s;
    --modal-width-lg: 70vw;
    --modal-width-sm: 90vw;
}

/* Container Styling */
.adminOrdersContainer {
    padding: 20px;
    padding-top: 80px;
    max-width: 1200px;
    margin: auto;
    background-color: var(--bg-color);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}

/* Title */
.adminOrdersTitle {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--primary-color);
    font-weight: bold;
}

/* Table Styles */
.adminOrdersTable {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--table-bg-color);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.adminOrdersTable th,
.adminOrdersTable td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    transition: background-color var(--transition-speed);
}

.adminOrdersTable th {
    background-color: var(--primary-color);
    color: #ffffff;
}

.adminOrdersTable tr:hover {
    background-color: rgba(163, 217, 165, 0.15); /* Soft pastel green highlight */
}

/* Product Images and Names in Table */
.productInfo {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    gap: 10px; /* Space between the name and image */
    margin-bottom: 10px; /* Add spacing between each product in the cell */
}

.productImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0; /* Start with hidden image */
    transition: opacity 0.3s ease-in-out;
  }
  .productImage.imageLoaded {
    opacity: 1; /* Show image when loaded */
  }

  .imageLoaded ~ .imagePlaceholder {
    opacity: 0; /* Hide spinner after image loads */
  }
  
/* Button Styles */
.adminOrdersButton {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    transition: transform var(--transition-speed), background-color var(--transition-speed);
    font-size: 14px;
}

.adminOrdersButton:hover {
    transform: translateY(-2px);
    background-color: var(--success-color);
}

/* Modal Styles */
.modalHeader {
    background-color: var(--primary-color);
    color: #ffffff;
}

.modalBody {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--table-bg-color); /* Match modal background */
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
}

.modalCloseButton {
    padding: 8px 12px;
    border-radius: 5px;
    background-color: var(--danger-color);
    color: #ffffff;
    cursor: pointer;
    transition: background-color var(--transition-speed);
}

.modalCloseButton:hover {
    background-color: #e57373;
}

/* Order Details - Product List */
.orderSummary {
    list-style: none;
    padding: 0;
    margin: 0;
}

.orderItem {
    display: flex;
    align-items: center;
    gap: 20px; /* Increased gap for better spacing */
    margin-bottom: 15px; /* Adjusted margin for larger items */
    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--bg-color);
    box-shadow: var(--box-shadow);
}

.orderItemImage {
    width: 100px; /* Increased size */
    height: 100px; /* Increased size */
    object-fit: cover;
    border-radius: 10px; /* More prominent rounded corners */
    box-shadow: var(--box-shadow);
}

/* Loading Overlay */
.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingOverlay:before {
    content: '';
    width: 50px;
    height: 50px;
    border: 5px solid var(--primary-color);
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.cardImgContainer {
    position: relative;
    width: 80px;
    height: 80px;


  }


  .imagePlaceholder {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid #ddd;
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Media Queries */
@media (min-width: 768px) {
    .adminOrdersTable th,
    .adminOrdersTable td {
        padding: 15px 20px;
    }
}

@media (max-width: 768px) {
    .adminOrdersTable th,
    .adminOrdersTable td {
        padding: 10px;
        font-size: 14px;
    }

    .adminOrdersButton {
        width: 100%;
        margin-bottom: 5px;
    }

    .modal-lg {
        width: var(--modal-width-lg);
    }

    .modal-sm {
        width: var(--modal-width-sm);
    }
}
/* Full Image in Modal */
.fullImage {
    width: 100%; /* Make the image take full width */
    max-height: 80vh; /* Limit the height to avoid overflow */
    object-fit: contain; /* Ensure the image is scaled proportionally */
    display: block; /* Center the image */
    margin: 0 auto; /* Center the image horizontally */
    border-radius: 10px; /* Rounded corners */
    box-shadow: var(--box-shadow); /* Add a shadow for better visibility */
}
.productInfo span {
    font-size: 14px; /* Adjust font size for readability */
    text-align: center; /* Center the product name */
    color: #000; /* Default black for the text */
    word-break: break-word; /* Break long names if necessary */
}

.printButton {
    background-color: #50852d;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: auto;
    cursor: pointer;
  }
  
  .printButton:hover {
    background-color: #0056b3;
  }
  


  .filterContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filterLabel {
    font-weight: bold;
  }
  
  .filterSelect {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .weeklyOrdersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .weeklyOrdersTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .weeklyOrdersTable {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
  }
  
  .weeklyOrdersCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 14%;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 8px;
    text-align: center;
  }
  
  .weeklyOrdersDate {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .weeklyOrdersCount {
    font-size: 18px;
    font-weight: bold;
    color: #A47551;
  }
  