/* Center the entire homepage content */

@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');

.homepageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
}

/* Hero section styles */
.heroSection {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=block');

/* Hero Text */
.heroText { 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 4rem;
  font-weight: normal;
  text-align: center;
  font-family: 'Tangerine', cursive; /* Ensure this matches */
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .heroSection {
    height: 300px;
  }

  .heroImage {
    object-position: center top;
  }

  .heroText {
    font-size: 1.8rem;
    padding: 15px 30px;
  }
}

@media (min-width: 1440px) {
  .heroSection {
    height: 500px;
  }
}

/* Content title and description styles */
.homepageContent h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
  margin: 20px auto;
}

.homepageContent p {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 800px;
  margin: 20px auto;
}

/* Section styles */
.section {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
}

.sectionImage {
  width: 100%;
  max-width: 700px;
  max-height: 700px;
  object-fit: cover;
  margin: 20px auto;
  border-radius: 10px;
  transition: max-height 0.3s ease;
}

/* Carousel styles */
.carouselContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.carouselItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.carouselImage {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.carouselCaption {
  text-align: center;
  padding: 20px;
}

/* On Image section styles */
.onImageSection {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.onImageSectionImage {
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  border-radius: 10px;
  transition: max-height 0.3s ease;
}

.onImageSectionText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

/* Modal styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popupImage {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

/* Reviews section styles */
.reviewCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  background-color: #fff;
  height: 300px;
  overflow: hidden;
}

.reviewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 150px;
}

.reviewContent h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.reviewContent p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.textSection p {
  white-space: pre-wrap;
}

/* Social media carousel styles */
.socialMediaCarousel {
  width: 100%;
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
  text-align: center;
}

.socialMediaItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.socialMediaEmbed {
  width: 100%;
  max-width: 400px;
  height: 750px;
  border-radius: 10px;
}

.socialMediaProfilesContainer {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin: 40px auto;
  padding: 20px;
}

.socialMediaProfileItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialMediaEmbedProfile {
  width: 100%;
  max-width: 400px;
  height: 420px;
  border: none;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .sectionImage,
  .onImageSectionImage {
    max-height: 250px;
  }

  .carouselImage {
    height: 300px;
  }

  .avatar {
    width: 50px;
    height: 50px;
  }

  .socialMediaProfilesContainer {
    flex-direction: column;
    gap: 20px;
  }
}
