.footer {
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  font-size: 14px;
  margin-top: 20px; /* Adds space between content and footer */
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}

.container div {
  flex: 1;
  margin: 10px;
}

.about, .customerService, .contact, .social {
  margin-bottom: 20px;
}

.about h4, .customerService h4, .contact h4, .social h4 {
  margin-bottom: 10px;
}

/* Remove bullets from the customerService list */
.customerService ul {
  list-style: none;
  padding-left: 0;
}

.customerService ul li {
  margin-bottom: 5px;
}

.contact p, .social a {
  margin: 5px 0;
  line-height: 1.6;
}

/* Footer link styles */
.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Payment and copyright info */
.paymentInfo {
  text-align: center;
  margin-top: 20px;
}

.copyright {
  text-align: center;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack sections vertically on mobile */
    align-items: center;
    text-align: center;
  }

  .container div {
    margin: 10px 0; /* Adjust margins for stacked layout */
  }

  .contact p, .social a {
    display: block;
    text-align: center;
  }
}
