.whatsappContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: right 0.3s ease; /* Smooth transition when moving */
}

.whatsappButton {
  background-color: #25D366;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.whatsappButton img {
  width: 40px;
  height: 40px;
}

.whatsappButton:hover {
  transform: scale(1.1);
}

.chatBubble {
  background-color: #e5fff3; /* Lighter green to resemble a WhatsApp message */
  color: #075e54; /* WhatsApp's message text color */
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  max-width: 180px;
  position: relative;
}

.chatBubble::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #e5fff3 transparent transparent transparent;
}

.cartOpen {
  right: 405px; /* Increase the amount to move it further left */
}
