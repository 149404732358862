.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 80px;
}

.formWrapper {
    background: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    animation: fadeIn 1s ease-in-out;
}

/* Other styles remain the same... */


.formWrapper h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    text-align: center;
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.formGroup input:focus {
    border-color: #ff7f50;
    box-shadow: 0 0 8px rgba(255, 127, 80, 0.3);
    outline: none;
}

.btnPrimary {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background: #ff7f50;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    transition: all 0.3s ease;
    cursor: pointer;
}

.btnPrimary:hover {
    background: #e36414;
}

.btnPrimary:active {
    background: #cc5200;
    transform: scale(0.98);
}

.footer {
    text-align: center;
    margin-top: 20px;
    color: #666;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
